/**
 * @description 公共方法
 */

import { token, host, requestGet } from '@/request'
import { getFolderOrFileApi } from '@/request/apiSuffix'
import axios from 'axios'

class BaseFunc {
  // 获取文件夹/文件
  async getFolderData (filePath = '') {
    const fileList = await requestGet(getFolderOrFileApi, { filePath })
    return fileList
  }

  // excel 文件下载
  async downloadFile ({ filePath, fileName }) {
    const res = await axios({
      headers: {
        Authorization: token
      },
      method: 'get',
      responseType: 'blob',
      url: `${host}${filePath}`
    })
    let blob = new Blob([res.data])
    let objectUrl = URL.createObjectURL(blob)
    let a = document.createElement('a')
    let fname = fileName
    a.href = objectUrl
    a.setAttribute('download', fname)
    document.body.appendChild(a)
    a.click()
  }

  // 深拷贝函数
  deepClone (oldData = {}) {
    if (typeof oldData !== 'object' || oldData === null) return oldData
    let result
    if (oldData instanceof Array) {
      result = []
    } else {
      result = {}
    }
    for (let key in oldData) result[key] = this.deepClone(oldData[key])
    return result
  }
}

export default new BaseFunc()

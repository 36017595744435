<template>
  <router-view/>
</template>

<style lang="less">
* {
  margin: 0;
  padding: 0;
  border: 0
}
ul, ol, li {
  list-style: none;
}
em, i {
  font-style: normal;
}
a {
  text-decoration: none;
}
</style>

import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    children: [
      {
        path: 'folder',
        component: () => import('@/components/FolderOrFile.vue')
      },
      // 二级文件或目录
      {
        path: 'folder/child',
        component: () => import('@/components/ChildFolderOrFile.vue')
      },
      // 最后一级文件
      {
        path: 'folder/child/file',
        component: () => import('@/components/File.vue')
      },
      // 配置表
      {
        path: 'tableSetting',
        component: () => import('@/components/TableSetting.vue')
      }
    ],
    redirect: '/folder' // 默认显示
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  // history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router

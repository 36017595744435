import { createStore } from 'vuex'
import Common from '@/assets/js/common'

export default createStore({
  state: {
    freshFolderListData: []
  },
  mutations: {
    getFolderListData (state, data) {
      state.freshFolderListData = data
    }
  },
  actions: {
    async getData ({ commit }, filePath) {
      // 获取到子路由列表数据
      const res = await Common.getFolderData(filePath)
      const { data } = res.data
      data.forEach((item, index) => {
        item.sort = index + 1
      })
      // data.sort((a, b) => b.sort - a.sort)
      commit('getFolderListData', data)
    }
  },
  modules: {
  }
})

/**
 * @description api接口请求后缀名
 */

// 文件上传
export const uploadFileApi = '/ddoa/attendance/records/import/'
// 获取文件夹/文件
export const getFolderOrFileApi = '/ddoa/attendance/report/list'
// 配置表
export const SettingTable = '/ddoa/attendance/setting/list'
// 修改配置表
export const updateSettingTable = '/ddoa/attendance/setting/update'

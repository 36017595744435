/**
 * @description api 接口
 */
import axios from 'axios'

// 线上
export const host = 'https://api.daxinbuye.com/offical'
// 本地
// export const host = 'http://192.168.2.200:19001/offical'
export const token = '1a5394e61ca8b473973cef0b6f79b195'

/**
 * @description axios 请求
 * @param {String} method GET/POST
 * @param {Object} data 参数
 * @param {String} suffixApiUrl 接口后面地址
 * @param {String} ContentType 解析格式
 * @returns request
 */
export const requestApi = async (method = 'GET', data, suffixApiUrl, ContentType = 'application/json; charset=UTF-8') => {
  const res = await axios({
    url: `${host}${suffixApiUrl}`,
    method,
    data,
    headers: {
      Authorization: token,
      'Content-Type': ContentType
    }
  })
  return res
}

/**
 * post 请求
 * @param {String} url 地址
 * @param {Object} params 参数
 * @returns request data
 */
export const requestPost = async (url, params = {}) => {
  const res = await axios.post(host + url, params, {
    headers: {
      Authorization: token
    }
  })
  return res
}

/**
 * get 请求
 * @param {String} url 地址
 * @param {Object} params 参数
 * @returns request data
 */
export const requestGet = async (url, params = {}) => {
  const res = await axios.get(host + url, {
    params,
    headers: {
      Authorization: token
    }
  })
  return res
}
